<template>
  <div class="home">
    <navbar/>

    <main>
      <section id="intro">
        <div class="intro-header text-white"
             :style="{backgroundImage: 'url(' + require('@/assets/img/richard-walz-startseite.jpg') + ')', backgroundColor: '#4a4d55'}">
          <div class="d-none d-md-flex flex-column justify-content-center h-100 ml-5">
            <h1 class="title display-3 d-none d-md-block">Hi, ich bin <br
                class="d-none d-md-block d-lg-none"> Richard Walz</h1>

            <div class="text-uppercase d-none d-md-block">Buchautor</div>
          </div>
        </div>

        <div class="intro-footer container text-center">
          <h2 class="display-2 font-weight-bold mt-5">Richard Walz</h2>

          <div class="description text-uppercase">Buchautor</div>
        </div>
      </section>

      <section id="about" class="container">
        <h3 class="section-title container">Mehr über mich</h3>

        <div class="custom-divider"></div>

        <div class="row">
          <div class="col-md-6 text-right mb-4">
            <img src="@/assets/img/ueber-mich-richard-walz.png" class="img-fluid" alt="Richard Walz">
          </div>

          <div class="col-md-6">
            <p>
              Mein Name ist <strong>Richard Walz</strong>. Ich bin <strong>Buchautor</strong> und habe ein Studium zum
              <strong>psychologischen Berater</strong> absolviert.
            </p>

            <p>
              Meine Neugier und die Begeisterung für das Thema <strong>Verkaufsstrategien</strong> haben dazu geführt,
              dass mein
              berufliches Leben mehrere Stationen hatte. Mit viel Mut, Erfolg und Herzblut habe ich seit dem Jahr 2000
              unterschiedliche berufliche Wege im Vertrieb beschritten.
            </p>

            <p>
              Nach meinem erfolgreich <strong>absolvierten betriebswirtschaftlichen Studium</strong> mit dem Schwerpunkt
              Versicherungen und
              zahlreichen <strong>psychologischen Ausbildungen</strong> war ich jahrelang in mehreren Unternehmen tätig.
              Es folgten
              verschiedene Führungspositionen im mittleren Management.
            </p>

            <p>
              Seit dem Jahr 2010 beschäftige ich mich intensiv mit dem Thema <strong>Verkaufsstrategien</strong> und der
              <strong>menschlichen Psychologie</strong>. Die Grundlagen meiner Methoden liegen in der Prozessberatung
              sowie der <strong>systemischen Beratung</strong>.
            </p>
          </div>
        </div>
      </section>

      <section id="service" class="container">
        <h3 class="section-title">Was ich anbiete</h3>

        <div class="custom-divider"></div>

        <div class="row">
          <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-4 text-center mb-4">
            <div class="mb-3">
              <i class="mdi mdi-book-open-page-variant"></i>
            </div>

            <h2 class="text-uppercase h4">
              <a
                  href="https://www.amazon.de/Richard-Walz-Das-Praxisbuch-Verkauf/dp/3981788885"
                  target="_blank"
                  title="Link zum Buch 'Das Praxisbuch für den Verkauf'">
                Buchautor
              </a>
            </h2>

            <p>
              Meine 18-Jährige Vertriebserfahrung habe ich in meinem Buch "Das Praxisbuch für den Verkauf"
              zu Papier gebracht. Das Buch ist für alle, die ein erfolgreiches Verkaufsgespräch lernen
              oder optimieren möchten.
            </p>
          </div>
        </div>
      </section>

      <section id="video">
        <div class="container">
          <h3 class="section-title">Ein kurzer Einblick</h3>

          <div class="custom-divider"></div>

          <div class="embed-responsive embed-responsive-16by9">
            <iframe class="embed-responsive-item"
                    src="https://www.youtube-nocookie.com/embed/4qNudLz2nY4?rel=0"
                    allowfullscreen></iframe>
          </div>
        </div>
      </section>

      <section id="team" class="container">
        <h3 class="section-title">Ohne Team geht´s nicht</h3>

        <div class="custom-divider"></div>

        <div class="row">
          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/marco-sander.jpeg" class="rounded-circle" alt="Bild von Marco Sander">

            <h4 class="my-4">
              <a href="https://de.straight-mind.com" target="_blank" title="Marco Sander">
                Marco Sander
              </a>
            </h4>

            <p>
              Ich bin Marco Sander. Meine Vision ist klar: „Ich gebe Menschen in einer klaren und
              praktischen Art und Weise die wichtigsten Erkenntnisse über sich selbst. Dadurch wird man im
              Business und im privaten Leben effektiver und zufriedener.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/florian-roski.jpeg" class="rounded-circle"
                 alt="Bild von Dr. Florian Roski">

            <h4 class="my-4">
              <a href="https://www.f-q.de" target="_blank" title="Quarters Wirtschaftssoietät">
                Dr. Florian Roski
              </a>
            </h4>

            <p>
              Mein Name ist Dr. Florian Roski genannt Flo. Ich bin Geschäftsführer der four-quarters
              Wirtschaftssoietät GmbH und Bestsellerautor. Die Berufung meines Teams und mir ist es,
              Unternehmer zu beraten und auf dem Weg zum Erfolg zu begleiten.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/johannes-beyer.jpg" class="rounded-circle" alt="Johannes Beyer">

            <h4 class="my-4">
              <a href="https://www.blue-panda.de" target="_blank" title="Blue Panda">
                Johannes Beyer
              </a>
            </h4>

            <p>
              Als Webentwickler stehe ich Ihnen mit Rat und Tat im Bereich
              Homepage, E-Commerce und Web-Apps zur Seite.
              Durch ein professionelles Erscheinungsbild im Web,
              haben Sie eine deutlich positivere Wirkung auf Ihre Kunden.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/klaus-ludwig.jpg" class="rounded-circle" alt="Klaus Ludwig">

            <h4 class="my-4">
              <a href="http://www.netexx.com" target="_blank" title="Klaus Ludwig">
                Klaus Ludwig
              </a>
            </h4>

            <p>
              Ich bin Experte rund um das Thema technische Datensicherheit. Von DSGVO bis zu individuellen
              Datensicherungslösungen.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/manuel-koch.jpg" class="rounded-circle" alt="Manuel Koch">

            <h4 class="my-4">
              <a href="http://www.manuel-koch.tv/de/manuel-koch/" target="_blank" title="Manuel Koch">
                Manuel Koch
              </a>
            </h4>

            <p>
              Für Menschen, die in der Öffentlichkeit stehen, ist Medientraining unverzichtbar. Ich
              trainiere z.B. Vorstände, wie sie in den Medien einen guten Eindruck machen.
            </p>
          </div>

          <div class="col-md-6 col-lg-4 text-center mb-4">
            <img src="@/assets/img/beatrice-schmidt.jpg" class="rounded-circle" alt="Johannes Beyer">

            <h4 class="my-4">Beatrice Köhler</h4>

            <p>
              Ich unterstütze Unternehmen bei dem Thema Suchmaschinenoptimierung. Somit wird jedes Unternehmen
              im Internet deutlich sichtbarer.
            </p>
          </div>
        </div>
      </section>

      <section id="testimonials" class="container">
        <h3 class="section-title">Erfahrungen meiner Kunden</h3>

        <div class="text-muted text-center mb-3">
          Das sagen meine Kunden über mich
        </div>

        <div class="custom-divider"></div>

        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4">
            <div class="embed-responsive embed-responsive-16by9 mb-4">
              <iframe
                  src="https://player.vimeo.com/video/256041779?autoplay=0&loop=0&title=0&portrait=0&byline=0&color&autopause=0"
                  allowfullscreen></iframe>
            </div>

            <blockquote class="blockquote text-center">
              <p class="mb-0">Das Verkaufstraining hat mir viel für meine Verkaufsfähigkeiten gebracht.
                Und ich werde in
                Zukunft
                mit Richard weiterarbeiten.</p>
              <footer class="blockquote-footer"><cite title="David Ege">David Ege</cite> - Selbständiger
              </footer>
            </blockquote>
          </div>

          <div class="col-md-6 col-lg-4 mb-4">
            <div class="embed-responsive embed-responsive-16by9 mb-4">
              <iframe
                  src="https://player.vimeo.com/video/256041506?autoplay=0&loop=0&title=0&portrait=0&byline=0&color&autopause=0"
                  allowfullscreen></iframe>
            </div>

            <blockquote class="blockquote text-center">
              <p class="mb-0">Ich konnte nach einer Woche schon die Praxistipps von Richard umsetzen. Ich
                bin begeistert.</p>
              <footer class="blockquote-footer"><cite title="Michael Neubauer">Michael Neubauer</cite> -
                Führungskraft
              </footer>
            </blockquote>
          </div>

          <div class="col-md-6 offset-md-3 col-lg-4 offset-lg-0 mb-4">
            <div class="embed-responsive embed-responsive-16by9 mb-4">
              <iframe
                  src="https://player.vimeo.com/video/256593117?autoplay=0&loop=0&title=0&portrait=0&byline=0&color&autopause=0"
                  allowfullscreen></iframe>
            </div>

            <blockquote class="blockquote text-center">
              <p class="mb-0">Trotz der Vorurteile, war ich von seinem Training hingerissen.
                Er war der erste Trainer, der mir wirklich weiterhelfen konnte.</p>
              <footer class="blockquote-footer"><cite title="Ulrike Kauderer">Ulrike Kauderer</cite> -
                Unternehmerin
              </footer>
            </blockquote>
          </div>
        </div>
      </section>
    </main>

    <contact-footer/>
  </div>
</template>

<script>
import Navbar from './Navbar'
import ContactFooter from './Footer'

export default {
  name: 'home',
  components: {
    Navbar,
    ContactFooter
  }
}
</script>

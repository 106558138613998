<template>
  <footer id="contact">
    <div class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-8 col-lg-4 mb-4">
            <div class="text-uppercase display-5 pl-0 mb-2 pt-5 pt-md-0">Kontakt</div>

            <p>
              Du möchtest mit mir Kontakt aufnehmen oder hast noch Fragen?
            </p>

            <p>
              Dann fülle das Formular aus und ich werde mich in kürzester Zeit bei dir melden.
            </p>

            <div class="my-4 text-center text-md-left">
              <a class="social-icon social-icon--facebook mr-1" href="http://facebook.com/1walz"
                 target="_blank">
                <i class="mdi mdi-facebook"></i>
              </a>

              <a class="social-icon social-icon--xing ml-1"
                 href="https://www.xing.com/profile/RIchard_Walz2/cv?sc_o=ps2867" target="_blank">
                <i class="mdi mdi-xing"></i>
              </a>
            </div>
          </div>

          <div class="col-md-4 mb-4">
            <address>
              <div class="d-flex mb-2 text-center flex-column">
                <i class="mdi mdi-map-marker mr-2"></i>

                <div>
                  Brückenstraße 12<br>
                  90419 Nürnberg<br>
                  Deutschland
                </div>
              </div>

              <div class="d-flex mb-2 text-center flex-column">
                <i class="mdi mdi-email-outline mr-2"></i>

                <div>
                  <a href="mailto:info@richard-walz.de">info(at)richard-walz.de</a>
                </div>
              </div>

              <div class="d-flex flex-column text-center">
                <i class="mdi mdi-phone mr-2"></i>

                <div>
                  <a href="tel:+49 174 2375475">+49 174 2375475</a>
                </div>
              </div>
            </address>
          </div>

          <div class="col-md-12 col-lg-4 mb-4">
            <b-alert :variant="alert.type" :show="alert.text.length" dismissible
                     @dismissed="resetAlert()">{{ alert.text }}
            </b-alert>

            <b-form @submit="onSubmit" method="post" action="/mail.php">
              <b-form-group>
                <b-form-input
                    type="email"
                    v-model="form.email"
                    name="mail"
                    required
                    placeholder="E-Mail">
                </b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-input
                    type="text"
                    v-model="form.name"
                    name="name"
                    required
                    placeholder="Name">
                </b-form-input>
              </b-form-group>

              <b-form-group>
                <b-form-textarea v-model="form.message"
                                 placeholder="Nachricht"
                                 name="message"
                                 :rows="3"
                                 :max-rows="6"
                                 required>
                </b-form-textarea>
              </b-form-group>

              <b-form-group label-for="form-privacy">
                <b-form-checkbox id="form-privacy" v-model="form.checked" required>
                  Ich habe die Hinweise zum <a href="/datenschutz" target="_blank">Datenschutz</a>
                  gelesen und zur Kenntnis genommen.
                </b-form-checkbox>
              </b-form-group>

              <div class="text-right">
                <b-button type="submit">Senden</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
    </div>

    <div class="second-footer">
      <div class="container py-3">
        <router-link to="/impressum">Impressum</router-link>
        -
        <router-link to="/datenschutz">Datenschutz</router-link>
      </div>
    </div>
  </footer>
</template>

<script>
import axios from 'axios'

export default {
  name: 'contact-footer',
  data() {
    return {
      form: {
        email: '',
        name: '',
        message: '',
        checked: false
      },
      alert: {
        text: '',
        type: ''
      }
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();

      axios({
        method: 'post',
        url: '/mail.php',
        data: this.form,
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        this.alert.text = 'Vielen Dank für Ihre Anfrage. Wir melden uns umgehen bei Ihnen.';
        this.alert.type = 'success';

        // Reset to prevent spam.
        this.resetForm();
      }).catch(() => {
        this.alert.text = 'Es ist etwas schief gelaufen. Bitte versuchen Sie es nochmal.';
        this.alert.type = 'danger';

        // Reset to prevent spam.
        this.resetForm();
      });
    },
    resetForm: function () {
      this.form = {
        email: '',
        name: '',
        message: '',
        checked: false
      }
    },
    resetAlert: function () {
      this.alert = {
        text: '',
        type: ''
      }
    }
  },
}
</script>

import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.config.productionTip = false

import Home from './components/Home'
import Impress from './components/Impress'
import Privacy from './components/Privacy'

const routes = [
	{ path: '/', component: Home },
	{ path: '/impressum', component: Impress },
	{ path: '/datenschutz', component: Privacy }
]

new Vue({
	router: new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior (to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		}
	}),
	render: h => h(App)
}).$mount('#app')

<template>
    <div>
        <navbar :is-home="false"/>

        <main class="container pt-5 my-5">
          <h1>Impressum</h1>

          <h2>Angaben gemäß § 5 TMG</h2>
          <p>Richard Walz<br />
            Buchautor<br />
            Brückenstraße 12<br />
            90419 Nürnberg</p>

          <h2>Kontakt</h2>
          <p>Telefon: +49 174 2375475<br />
            E-Mail: info@richard-walz.de</p>

          <h2>EU-Streitschlichtung</h2>
          <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

          <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2><p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
        </main>

        <contact-footer/>
    </div>
</template>

<script>
	import Navbar from './Navbar'
	import ContactFooter from './Footer'

	export default {
		components: {
			Navbar,
			ContactFooter
		}
	}
</script>
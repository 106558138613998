<template>
    <div v-if="isHome">
        <div class="d-lg-none">
            <b-nav v-b-scrollspy="100" tag="nav" :class="{navIsTop: windowTopStat}">
                <b-nav-item href="#intro">Start</b-nav-item>
                <b-nav-item href="#about">Über mich</b-nav-item>
                <b-nav-item href="#service">Service</b-nav-item>
                <b-nav-item href="#video">Einblick</b-nav-item>
                <b-nav-item href="#team">Team</b-nav-item>
                <b-nav-item href="#testimonials">Erfahrungen</b-nav-item>
                <b-nav-item href="#contact">Kontakt</b-nav-item>
            </b-nav>
        </div>

        <div class="d-none d-lg-block">
            <b-nav justified v-b-scrollspy="40" tag="nav" :class="{navIsTop: windowTopStat}">
                <b-nav-item href="#intro">Start</b-nav-item>
                <b-nav-item href="#about">Über mich</b-nav-item>
                <b-nav-item href="#service">Service</b-nav-item>
                <b-nav-item href="#video">Einblick</b-nav-item>
                <b-nav-item href="#team">Team</b-nav-item>
                <b-nav-item href="#testimonials">Erfahrungen</b-nav-item>
                <b-nav-item href="#contact">Kontakt</b-nav-item>
            </b-nav>
        </div>
    </div>

    <div v-else>
        <div class="d-lg-none">
            <b-nav tag="nav">
                <b-nav-item to="/#intro">Start</b-nav-item>
                <b-nav-item to="/#about">Über mich</b-nav-item>
                <b-nav-item to="/#service">Service</b-nav-item>
                <b-nav-item to="/#video">Einblick</b-nav-item>
                <b-nav-item to="/#team">Team</b-nav-item>
                <b-nav-item to="/#testimonials">Erfahrungen</b-nav-item>
                <b-nav-item to="/#contact">Kontakt</b-nav-item>
            </b-nav>
        </div>

        <div class="d-none d-lg-block">
            <b-nav justified tag="nav">
                <b-nav-item to="/#intro">Start</b-nav-item>
                <b-nav-item to="/#about">Über mich</b-nav-item>
                <b-nav-item to="/#service">Service</b-nav-item>
                <b-nav-item to="/#video">Einblick</b-nav-item>
                <b-nav-item to="/#team">Team</b-nav-item>
                <b-nav-item to="/#testimonials">Erfahrungen</b-nav-item>
                <b-nav-item to="/#contact">Kontakt</b-nav-item>
            </b-nav>
        </div>
    </div>
</template>

<script>
	export default {
		props: {
			isHome: {
				default: true,
        type: Boolean
      }
    },
		data() {
			return {
				windowTopStat: true
			}
		},
		created() {
			if (this.isHome) {
				window.addEventListener('scroll', this.windowTop);
			}
		},
    mounted() {
			let el = this.$route.hash.length ? document.querySelector(this.$route.hash) : null

			if (el) {
				window.scroll({
					top: el.offsetTop,
					left: 0
				});
			}
    },
		methods: {
			windowTop: function () {
				this.windowTopStat = window.scrollY === 0;
			}
		},
		destroyed() {
			window.removeEventListener('scroll', this.windowTop);
		},
	}
</script>